import React from "react"
import { Link } from "gatsby"
import Layout from "@components/layout/layout"
import SeoComponent from "@components/seo"
import Particles from "preact-particles"


const NotFoundPage = ({ data }) => {
	return (
		<Layout location="auxiliary">
			<SeoComponent title="404: Not found" />
			<div className="auxiliary-page-wrapper">
				<div className="page-heading">
					<Particles className="page-heading-particles"
					id="tsparticles"
		            options={{
		            fpsLimit: 60,
		            particles: {
		              color: {
		                value: "#ffffff",
		              },
		              move: {
		                enable: true,
		                outMode: "out",
		                straight: false,
		                random: true,
		                speed: 1,
		                direction: "none",
		               
		              },
		              number: {
		                value: 25,
		              },
		              opacity: {
		                random: true,
		                animation: {
		                  enable: true,
		                }
		              },
		              shape: {
		                type: "circle",
		              },
		              size: {
		                value: 3,
		                random: true,
		                animation: {
		                  enable: true,
		                  speed: 2,
		                  minimumValue: 0.3,
		                },
		              },
		            },
		            detectRetina: true,
		          }}
				/>
					<div className="container text-center">
						<div className="row justify-content-center">
							<div className="col-lg-10">
								<div className="page-not-found">
									<div className="container text-center">
										<span className="four-oh-four">404</span>
										<h1 className="font-weight-bold not-found mb-4">
											NOT FOUND
										</h1>
										<p>The page you are looking for does not exist.</p>
										<p>
											Please check if the URL is entered correctly. If you think
											this is an error, contact us.
										</p>
										<Link to="/" className="btn btn-primary btn-orange mt-4">
											Return Home
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default NotFoundPage
